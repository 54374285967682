import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Title from "../components/title"
import { Accordion } from "../components/Accordion"
import SEO from "../components/SEO/seo"

const FaqContainer = styled.div`
  display: grid;
  grid-template-columns: 700px;
  justify-content: center;
  margin-bottom: 60px;
  @media (max-width: 767px) {
    padding: 0 15px;
    grid-template-columns: 80%;
    margin-bottom: 40px;
  }
  @media (max-width: 575px) {
    padding: 0 15px;
    grid-template-columns: 95%;
  }
`

export default ({ location }) => (
  <>
    <SEO title="FAQs" description="Frequently asked questions about Facebook group automation software." pathname={location.pathname} />
    <Title
      titleText="Frequently Asked Questions"
      titleSub="If you are facing any problem regarding Groupboss feel free to contact us."
      main
    />
    <FaqContainer>
      <Accordion
        title="Do I need to wait for a while after approving the requests?"
        content="Yes. You need to wait for a while so that the data can be 
          stored smoothly. If you are approving many requests at a time by 
          clicking on ‘Approve all by Groupboss’, then allow some more time 
          and do not change the page quickly."
      />
      <Accordion
        title="I am looking for adding people on automation as friends when they comment on other Facebook groups, Is it possible ?"
        content="No. It’s not possible."
      />
      <Accordion
        title="Is it possible to send information to both googlesheet and at the same time sync to autoresponder?"
        content="Yes, you can do that. You need to integrate the autoresponder for doing so. To know about how to Integrate different autoresponders with Groupboss, you can take the help of our YouTube channel. We have a separate tutorial for each autoresponder."
      />
      <Accordion
        title="Is it possible to auto-populate a user FB email account from their profile ?"
        content="Groupboss does not have this feature."
      />
      <Accordion
        title="Can I install the chrome plugin at home computer and office computer (2 devices)"
        content="Yes, you can do that. To do that you need to log in using the same email Id and Password."
      />

      <Accordion
        title="Is this extension legal or will I get banned by FB ?"
        content="We did not hear that Facebook can ban the extension."
      />

      <Accordion
        title="Can I get the free trial ?"
        content="Yes, 7 days free trial is available in our Yearly plan"
      />

      <Accordion
        title="Do I need to go in and change groups in the chrome plugin when I change groups in FB ?"
        content="No. You need to add your Facebook group once."
      />
      <Accordion
        title="Is it right that I have to grant access to Google every time I add new people to a group ?"
        content=" No. You need not grant access every time, 
        but you need to keep your Gmail account signed in. 
        But if you have different googlesheet of different Gmail 
        account then you need to grant access every time. To avoid 
        this hassle we suggest you use the same Gmail account for 
        all the groups. You can use different googlsheet for your 
        different groups and you need to configure that specific 
        googlesheet once. After that when you change the Facebook 
        group in Groupboss chrome extension, then your configured 
        googlesheet will be changed automatically."
      />
      <Accordion
        title="What type of data sync to autoresponder ?"
        content="In autoresponders, you will get first name, last name and email Id"
      />
      <Accordion
        title="Is it possible to sync the Facebook Profile link in autoresponders ?"
        content="So far we do not have the option of doing that. In autoresponders, 
        you will get the first name, last name, and Email Id."
      />
    </FaqContainer>
  </>
)
