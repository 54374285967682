import React from "react"
import styled from "styled-components"

const TileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0 15px 0;
  padding: 0 15px;

  @media (max-width: 767px) {
    text-align: center;
    margin: ${props => props.main && `30px 0 15px 0`};
  }
`

const TitleText = styled.p`
  color: #3d4d69;
  font-size: 32px;
  margin: 0 0 8px 0;
  font-weight: 700;
  line-height: 1.2;
`

const TitleH1 = styled.h1`
  color: #161f32;
  font-size: ${props => (props.fontSize ? props.fontSize : `42px`)};
  margin: 0;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.2;
  @media (max-width: 575px){
    font-size: 23px;
  }
`

const TitleSub = styled.p`
  color: #3d4d69;
  font-size: 18px;
  margin-bottom: 40px;
  margin-top: 15px;
  @media (max-width: 575px){
    font-size: 14px;
  }
`

export default props => (
  <TileContainer main={props.main}>
    {props.main ? (
      <TitleH1 fontSize={props.fontSize}> {props.titleText} </TitleH1>
    ) : (
      <TitleText> {props.titleText} </TitleText>
    )}
    {props.titleSub !== "" && <TitleSub> {props.titleSub} </TitleSub>}
  </TileContainer>
)
